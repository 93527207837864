<template>
  <loader />
</template>

<script>
export default {
  methods: {
    login: function () {
      const phone = localStorage.getItem("phone");
      const password = localStorage.getItem("password");
      this.$http
        .post("https://yalla-emtihan.com/backend/auth/supervisorLogin", {
          phone: "+249" + phone.substr(1, 11),
          password: password,
        })
        .then(
          function (data) {
            switch (data.status) {
              case 200:
                sessionStorage.setItem("token", data.body.token);
                sessionStorage.setItem("userTypeId",data.body.user_type_id);
                this.$router.push("/levels");
                break;
            }
          },
          (err) => {
            this.error();
            sessionStorage.clear();
            localStorage.clear();
            this.$router.push("/login");
          }
        );
    },
    error: function () {
      this.$toast.error(this.$t("verificationProcessError"), {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
  created() {
    this.login();
  },
};
</script>

<style scoped>
</style>